import Axios from '../../axiosConfig';
import { BACKEND_URL } from '../../config';
import { configure, makeAutoObservable, runInAction } from 'mobx';
import downloadjs from 'downloadjs';
import '../../styled/froalaLinkStyles.css';
import {
    AddGoalActionParams,
    Chapter,
    ChapterItem,
    ChapterNumber,
    CurrentDocument,
    DocumentApprovalParams,
    DocumentHistoryItem,
    DocumentHistoryTitle,
    DocumentSearchDocument,
    DocumentSearchParameters,
    DocumentShare,
    DownloadPowerPointParams,
    FocusArea,
    FocusAreaGoal,
    FocusAreaGoalAction,
    NewDocument,
    OrganizationDocument,
    PublishedDocument,
    RemovedFocusParams,
    SaveGoalActionParams,
    ShareDocumentParams,
    ShvkDocumentType,
} from '../../types/document';
import {
    AddCustomIndicator,
    AddEvaImpactOnTargetParams,
    Indicator,
    IndicatorPackage,
    IndicatorPackageTheme,
    RemoveIndicatorFromImpactOnTargetParams,
    RemoveIndicatorParams,
    SaveEvaModelParams,
    SaveIndicatorModelParams,
} from '../../types/indicator';
import {
    AddEvaDecisionOptionParams,
    EditorDecisionOption,
    EditorImpactOnTarget,
    EditorPerspective,
} from '../../types/evaTemplate';
import { FroalaConfigObject } from '../../types/froalaConfig';
import IndicatorPrintOption from '../../types/indicatorPrintOption';
import RootModule from '../rootModule';

configure({ enforceActions: 'observed' });

export default class DocumentModule {
    private rootModule: RootModule;
    private allPublishedDocs: PublishedDocument[] = [];
    private latestPublishedDocs: PublishedDocument[] = [];
    private docTypes: ShvkDocumentType[] = [];
    private organizationDocs: OrganizationDocument[] = [];
    private activeDocument: CurrentDocument = {} as CurrentDocument;
    private currentDocumentHistoryTitles: DocumentHistoryTitle[] = [];
    private currentDocumentHistoryDates: string[] = [];
    private documentShares: DocumentShare[] = [];
    private focusAreaTable = false;
    private isPreviewMode = false;

    constructor(rootModule: RootModule) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.rootModule = rootModule;
    }

    public get isPreview(): boolean {
        return this.isPreviewMode;
    }
    public set isPreview(value: boolean) {
        this.isPreviewMode = value;
    }

    public get isChapterLocked(): boolean {
        return this.currentDocument.chapters.some((chp) => chp.locked);
    }

    public get currentDocument(): CurrentDocument {
        return this.activeDocument;
    }

    public get allPublishedDocuments(): PublishedDocument[] {
        return this.allPublishedDocs;
    }

    public get largeReportTermsFromPublishedDocuments(): string[] {
        const termsList: string[] = [];
        this.allPublishedDocuments.forEach((document: PublishedDocument) =>
            document.yearBegin &&
            document.yearEnd &&
            !termsList.some((term) => term === document.yearBegin + ' - ' + document.yearEnd)
                ? termsList.push(document.yearBegin + ' - ' + document.yearEnd)
                : null,
        );
        return termsList.sort().reverse();
    }

    public get yearlyReportYearsFromPublishedDocuments(): number[] {
        const yearsList: number[] = [];
        this.allPublishedDocuments.forEach((document: PublishedDocument) =>
            document.yearBegin && !yearsList.some((year) => document.yearBegin === year)
                ? yearsList.push(document.yearBegin)
                : null,
        );
        return yearsList.sort().reverse();
    }

    public get focusAreaTableHidden(): boolean {
        return this.focusAreaTable;
    }
    public set focusAreaTableHidden(value: boolean) {
        this.focusAreaTable = value;
    }

    public get froalaFocusAreaConfig(): FroalaConfigObject {
        return {
            attribution: false,
            autofocus: true,
            editable: true,
            fontFamily: {
                Arial: 'Arial',
                Georgia: 'Georgia',
                Impact: 'Impact',
                Tahoma: 'Tahoma',
                'Times New Roman': 'Times New Roman',
                Verdana: 'Verdana',
                'Work Sans': 'Work Sans',
                'Work Sans Medium': 'Work Sans Medium',
            },
            fontFamilySelection: true,
            heightMax: 400,
            heightMin: 200,
            key: 'YNB3fH3A7A6C7A4B2B2C-8TMIBDIa1NTMNZFFPFZc1d1Ib2a1E1fA4A3G3G3F2B6D3C4C3C2==',
            language: this.rootModule.localization.locale === 'fi_FI' ? 'fi' : 'sv',
            linkAlwaysBlank: true,
            linkConvertEmailAddress: false,
            linkEditButtons: ['linkEdit', 'linkRemove', 'linkStyle'],
            linkInsertButtons: ['linkBack'],
            linkStyles: {
                noUnderline: this.rootModule.localization.locale === 'fi_FI' ? 'ei alleviivausta' : 'no underline',
                blackColor: this.rootModule.localization.locale === 'fi_FI' ? 'väri: musta' : 'color: black',
            },
            pastePlain: true,
            placeholderText: '',
            spellcheck: false,
            toolbarButtons: [
                'fullscreen',
                'bold',
                'italic',
                'textColor',
                'underline',
                'paragraphFormat',
                'strikeThrough',
                'fontFamily',
                'fontSize',
                'align',
                'outdent',
                'indent',
                'formatOL',
                'formatUL',
                'insertTable',
                'undo',
                'redo',
                'clearFormatting',
                'html',
                'insertLink',
                'selectAll',
            ],
            quickInsertButtons: ['table', 'ul', 'ol', 'hr'],
            toolbarButtonsMD: null,
            toolbarButtonsSM: null,
            toolbarButtonsXS: null,
        };
    }

    public get froalaConfig(): FroalaConfigObject {
        return {
            attribution: false,
            autofocus: true,
            editable: true,
            fileInsertButtons: ['fileInsert'],
            fileUploadURL: BACKEND_URL + 'document/attachment',
            events: {
                /*If file is inserted via froala text editor's 'Insert file' toolbar button, link does not open to new tab
                 * when it is clicked. Therefore, attribute target=_blank is added manually to link elements here.
                 * */
                'file.inserted': function (file: { 0: HTMLAnchorElement; length: number }) {
                    file['0'].setAttribute('target', '_blank');
                    file['0'].setAttribute('rel', 'noreferrer noopener');
                },
            },
            fontFamily: {
                Arial: 'Arial',
                Georgia: 'Georgia',
                Impact: 'Impact',
                Tahoma: 'Tahoma',
                'Times New Roman': 'Times New Roman',
                Verdana: 'Verdana',
                'Work Sans': 'Work Sans',
                'Work Sans Medium': 'Work Sans Medium',
            },
            fontFamilySelection: true,
            heightMax: 400,
            heightMin: 200,
            imageInsertButtons: ['imageUpload'],
            imageEditButtons: ['imageAlign', 'imageDisplay', 'imageSize', 'imageAlt', 'imageReplace', 'imageRemove'],
            imagePasteProcess: true,
            imageUploadURL: BACKEND_URL + 'document/image',
            key: 'YNB3fH3A7A6C7A4B2B2C-8TMIBDIa1NTMNZFFPFZc1d1Ib2a1E1fA4A3G3G3F2B6D3C4C3C2==',
            language: this.rootModule.localization.locale === 'fi_FI' ? 'fi' : 'sv',
            linkAlwaysBlank: true,
            linkConvertEmailAddress: false,
            linkEditButtons: ['linkEdit', 'linkRemove', 'linkStyle'],
            linkInsertButtons: ['linkBack'],
            linkStyles: {
                noUnderline: this.rootModule.localization.locale === 'fi_FI' ? 'ei alleviivausta' : 'no underline',
                blackColor: this.rootModule.localization.locale === 'fi_FI' ? 'väri: musta' : 'color: black',
            },
            pastePlain: true,
            placeholderText: '',
            spellcheck: false,
            toolbarButtons: [
                'fullscreen',
                'bold',
                'italic',
                'textColor',
                'underline',
                'paragraphFormat',
                'strikeThrough',
                'fontFamily',
                'fontSize',
                'align',
                'outdent',
                'indent',
                'formatOL',
                'formatUL',
                'insertTable',
                'undo',
                'redo',
                'clearFormatting',
                'html',
                'insertImage',
                'insertLink',
                'insertFile',
                'selectAll',
                '-',
            ],
            quickInsertButtons: ['image', 'table', 'ul', 'ol', 'hr'],
            toolbarButtonsMD: null,
            toolbarButtonsSM: null,
            toolbarButtonsXS: null,
        };
    }

    public async downloadEvaDecisionOptionCompareTablePDF(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/downloadEvaDecisionOptionCompareTablePDF',
            data: { id: this.currentDocument.id },
        });
        downloadjs(`data:application/pdf;base64,${response.data}`, `${this.currentDocument.name}.pdf`, '.pdf');
    }

    public async downloadEvaDecisionOptionCompareTableExcel(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/downloadEvaDecisionOptionCompareTableExcel',
            data: { id: this.currentDocument.id },
        });
        downloadjs(
            `data:application/vnd.ms-excel;base64,${response.data}`,
            `${this.currentDocument.name}.xlsx`,
            '.xlsx',
        );
    }

    public async createGraphImage(svg: string): Promise<string> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/createGraphImage',
            data: { svg: svg },
        });
        return response.data;
    }

    public async addFocusArea(chapterId: number, title: string): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/addFocusArea',
            data: { chapterId, title },
        });
        runInAction(() => {
            this.currentDocument.chapters
                .find((chapter: Chapter) => chapter.isFocusArea)!
                .focusAreas.push(response.data);
        });
    }

    public async saveFocusArea(focusAreaId: number, mainTitle: string, title: string): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/saveFocusArea',
            data: { id: focusAreaId, mainTitle, title },
        });

        if (response.status === 200) {
            const focusAreaChapter = this.currentDocument.chapters.find((chapter: Chapter) => chapter.isFocusArea);
            if (focusAreaChapter) {
                runInAction(() => {
                    focusAreaChapter.focusAreas = focusAreaChapter.focusAreas.map((focusArea: FocusArea) => {
                        if (focusArea.id === focusAreaId) {
                            return response.data;
                        }
                        return focusArea;
                    });
                });
            }
        }
    }

    public async removeFocusArea(focusAreaId: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/removeFocusArea',
            data: { id: focusAreaId },
        });
        runInAction(() => {
            if (response.status === 200) {
                this.currentDocument.chapters.find((chapter: Chapter) => chapter.isFocusArea)!.focusAreas =
                    this.currentDocument.chapters
                        .find((chapter: Chapter) => chapter.isFocusArea)!
                        .focusAreas.filter((focusArea: FocusArea) => focusArea.id !== focusAreaId);
            }
        });
    }

    public async updateFocusAreaTableTitles(titles: any): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/updateFocusAreaTableTitles',
            data: { id: this.currentDocument.id, titles },
        });
        runInAction(() => {
            if (response.status === 200) {
                this.currentDocument.focusAreaTableTitles = response.data;
            }
        });
    }

    public async addGoal(focusAreaId: number, title: string): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/addGoal',
            data: { focusAreaId, goal: { title } },
        });
        runInAction(() => {
            this.currentDocument.chapters
                .find((chapter: Chapter) => chapter.isFocusArea)!
                .focusAreas.find((focusArea: FocusArea) => focusArea.id === focusAreaId)!
                .goals.push(response.data);
        });
    }

    public async saveGoal(
        focusAreaId: number,
        goalId: number,
        title: string,
        additionalColumn?: string,
    ): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/saveGoal',
            data: { id: goalId, title, additionalColumn },
        });
        if (response.status === 200) {
            const focusAreaChapter = this.currentDocument.chapters.find((chapter: Chapter) => chapter.isFocusArea);
            const focusArea = focusAreaChapter?.focusAreas.find((focusArea: FocusArea) => focusArea.id === focusAreaId);
            if (focusArea) {
                runInAction(() => {
                    focusArea.goals = focusArea.goals.map((goal: FocusAreaGoal) => {
                        if (goal.id === goalId) {
                            return response.data;
                        }
                        return goal;
                    });
                });
            }
        }
    }

    public async removeGoal(focusAreaId: number, goalId: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/removeGoal',
            data: { id: goalId },
        });
        if (response.status === 200) {
            const focusAreaChapter = this.currentDocument.chapters.find((chapter: Chapter) => chapter.isFocusArea);
            const focusArea = focusAreaChapter?.focusAreas.find((focusArea: FocusArea) => focusArea.id === focusAreaId);
            if (focusArea) {
                runInAction(() => {
                    focusArea.goals = focusArea.goals.filter((goal: FocusAreaGoal) => goal.id !== goalId);
                });
            }
        }
    }

    public async addGoalAction(focusAreaId: number, params: AddGoalActionParams): Promise<FocusAreaGoalAction> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/addGoalAction',
            data: { ...params },
        });
        runInAction(() => {
            this.currentDocument.chapters
                .find((chapter: Chapter) => chapter.isFocusArea)!
                .focusAreas.find((focusArea: FocusArea) => focusArea.id === focusAreaId)!
                .goals.find((goal: FocusAreaGoal) => goal.id === params.goalId)!
                .actions.push(response.data);
        });
        return response.data;
    }

    public async saveGoalAction(
        focusAreaId: number,
        goalId: number,
        params: SaveGoalActionParams,
    ): Promise<FocusAreaGoalAction> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/saveGoalAction',
            data: { ...params },
        });
        if (response.status === 200) {
            const focusAreaChapter = this.currentDocument.chapters.find((chapter: Chapter) => chapter.isFocusArea);
            const focusArea = focusAreaChapter?.focusAreas.find((area: FocusArea) => area.id === focusAreaId);
            const focusAreaGoal = focusArea?.goals.find((goal: FocusAreaGoal) => goal.id === goalId);
            if (focusAreaGoal) {
                runInAction(() => {
                    focusAreaGoal.actions = focusAreaGoal.actions.map((action: FocusAreaGoalAction) => {
                        if (action.id === params.id) {
                            return response.data as FocusAreaGoalAction;
                        }
                        return action;
                    });
                });
            }
        }
        return response.data;
    }

    public async removeGoalAction(focusAreaId: number, goalId: number, goalActionId: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/removeGoalAction',
            data: { id: goalActionId },
        });
        if (response.status === 200) {
            const focusAreaChapter = this.currentDocument.chapters.find((chapter: Chapter) => chapter.isFocusArea);
            const focusArea = focusAreaChapter?.focusAreas.find((area: FocusArea) => area.id === focusAreaId);
            const focusAreaGoal = focusArea?.goals.find((goal: FocusAreaGoal) => goal.id === goalId);
            if (focusAreaGoal) {
                runInAction(() => {
                    focusAreaGoal.actions = focusAreaGoal.actions.filter(
                        (action: FocusAreaGoalAction) => action.id !== goalActionId,
                    );
                });
            }
        }
    }

    public changeActiveDocumentName(name: string): void {
        this.activeDocument.name = name;
    }

    public changeActiveDocumentCoverPageText(text: string | null): void {
        this.activeDocument.coverPageText = text;
    }

    public async saveEvaDecisionOption(params: AddEvaDecisionOptionParams): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/saveEvaDecisionOption',
            data: { ...params },
        });
        runInAction(async () => {
            this.evaDecisionOptionChapter!.evaDecisionOptions = this.evaDecisionOptionChapter!.evaDecisionOptions.map(
                (d) => (d.id === response.data.id ? (response.data as EditorDecisionOption) : d),
            );
            await this.reloadCurrentDocument();
        });
    }

    public async addEvaDecisionOption(params: AddEvaDecisionOptionParams): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/addEvaDecisionOption',
            data: { ...params },
        });
        runInAction(async () => {
            this.evaDecisionOptionChapter?.evaDecisionOptions.push(response.data);
            await this.reloadCurrentDocument();
        });
    }

    public async removeEvaDecisionOption(decisionOptionId: number): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/removeEvaDecisionOption',
            data: { id: decisionOptionId },
        });
        this.removeEvaDecisionOptionFromList(decisionOptionId);
        await this.reloadCurrentDocument();
    }

    private removeEvaDecisionOptionFromList(id: number): void {
        if (this.evaDecisionOptionChapter?.evaDecisionOptions) {
            this.evaDecisionOptionChapter.evaDecisionOptions = this.evaDecisionOptionChapter?.evaDecisionOptions.filter(
                (decisionOption) => decisionOption.id !== id,
            );
        }
    }

    public async removeEvaPerspective(title: string, chapter: Chapter): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/removeEvaPerspective',
            data: { chapterId: chapter.id, title: title },
        });
        runInAction(() => {
            chapter.evaDecisionOptions.forEach(
                (option: EditorDecisionOption) =>
                    (option.evaPerspectives = option.evaPerspectives.filter(
                        (perspective: EditorPerspective) => perspective.title !== title,
                    )),
            );
        });
    }

    public async addEvaPerspective(title: string): Promise<void> {
        const chapter = this.currentDocument.chapters.find((chapter: Chapter) => chapter.isEvaDecisionOptionArea);
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/addEvaPerspective',
            data: { chapterId: chapter!.id, title: title },
        });
        runInAction(() => {
            if (response.status === 200) {
                chapter!.evaDecisionOptions.forEach((option: EditorDecisionOption) =>
                    option.evaPerspectives.push(
                        response.data[chapter!.evaDecisionOptions.findIndex((op) => op.id === option.id)],
                    ),
                );
            }
        });
    }

    public async saveEvaPerspective(id: number, comments: string): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/saveEvaPerspective',
            data: { id: id, comments: comments },
        });
    }

    public async createDocument(params: NewDocument): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/create',
            data: params,
        });
        this.setCurrentDocument(response.data);
    }

    public async updateDocumentChapters(params: any): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/updateDocumentChapters',
            data: {
                documentId: this.currentDocument.id,
                chapterModel: params,
            },
        });
        await runInAction(() => this.refreshCurrentDocument());
    }

    public hasSubChapters(chapterId: number): boolean {
        const foundChapter = this.currentDocument.chapters.find((chp) => chp.id === chapterId);
        if (foundChapter) {
            const currentChapterIndex = this.currentDocument.chapters.indexOf(foundChapter);
            // If the next chapter from current has higher level, then current chapter has subchapters.
            return foundChapter.chapterLevel < this.currentDocument.chapters[currentChapterIndex + 1]?.chapterLevel;
        }
        return false;
    }

    // Set the direct flag to true if only "first" level of subchapters are needed.
    public findSubChapters(chapterId: number, direct?: boolean): Chapter[] {
        const chapters = this.currentDocument.chapters;
        const foundChapter = chapters.find((chp) => chp.id === chapterId);
        const subChapters: Chapter[] = [];

        if (!foundChapter) return subChapters;

        const currentChapterIndex = chapters.indexOf(foundChapter);
        const nextChapters = chapters.slice(currentChapterIndex + 1, chapters.length);

        if (!nextChapters.length) return subChapters;

        if (direct) {
            // If the iterated chapter's level - 1 === found chapter's level,
            // it is a direct subchapter of the found chapter.
            for (let i = 0; i < nextChapters.length; i++) {
                if (nextChapters[i]?.chapterLevel - 1 === foundChapter.chapterLevel) {
                    subChapters.push(nextChapters[i]);
                } else if (nextChapters[i]?.chapterLevel === foundChapter.chapterLevel) {
                    // Break the loop if the iterated chapter's level is the same as found chapter's level.
                    return subChapters;
                }
            }
        } else {
            // If the iterated chapter's level is greater than the found chapter level,
            // it is a subchapter or a nested subchapter of the found chapter.
            for (let i = 0; i < nextChapters.length; i++) {
                if (nextChapters[i]?.chapterLevel > foundChapter.chapterLevel) {
                    subChapters.push(nextChapters[i]);
                } else {
                    return subChapters;
                }
            }
        }
        return subChapters;
    }

    public async resetIndicatorModel(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/resetIndicatorModel',
            data: { id: this.currentDocument.id },
        });
        if (response.status === 200) await runInAction(() => this.setIndicatorArea(response.data));
    }

    public async updateDocumentIndicatorAreaChapter(indicatorModel: SaveIndicatorModelParams): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/updateDocumentIndicatorAreaChapter',
            data: {
                documentId: this.currentDocument.id,
                indicatorModel: indicatorModel,
            },
        });
        if (response.status === 200) await runInAction(() => this.setIndicatorArea(response.data));
    }

    public async setIndicatorArea(indicatorPackages: IndicatorPackage[]): Promise<void> {
        await Promise.all(
            this.activeDocument.chapters.map((chapter: Chapter) => {
                if (chapter.isIndicatorArea) chapter.indicatorPackages = indicatorPackages;
                return chapter;
            }),
        );
    }

    public async updateDocumentFocusAreaChapter(
        focusAreaModel: any,
        removedItems: RemovedFocusParams[],
    ): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/updateDocumentFocusAreaChapter',
            data: {
                documentId: this.currentDocument.id,
                focusAreaModel: focusAreaModel,
                removedItems: removedItems,
            },
        });
        runInAction(() => {
            if (response.status === 200)
                this.currentDocument.chapters.find((chapter: Chapter) => chapter.isFocusArea)!.focusAreas =
                    response.data;
        });
    }

    public async updateDocumentEvaDecisionAreaChapter(params: SaveEvaModelParams): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/updateDocumentEvaDecisionAreaChapter',
            data: { documentId: this.currentDocument.id, ...params },
        });
        runInAction(() => {
            if (response.status === 200)
                this.currentDocument.chapters.find(
                    (chapter: Chapter) => chapter.isEvaDecisionOptionArea,
                )!.evaDecisionOptions = response.data;
        });
    }

    public async fetchAvailableDocumentTypes(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'document/availableDocumentTypes',
        });
        if (response.status === 200) runInAction(() => (this.docTypes = response.data));
    }

    public async chaptersStatus(): Promise<void> {
        if (this.activeDocument.id) {
            const response = await Axios({
                method: 'POST',
                url: BACKEND_URL + 'document/chaptersStatus',
                data: { documentId: this.activeDocument.id },
            });
            if (response.status === 200) {
                response.data.forEach((c: any) => {
                    const chapter = this.activeDocument.chapters.find((chapter) => chapter.id === c.id);
                    if (chapter) {
                        if (chapter.version !== c.version) {
                            this.getChapter(chapter.id);
                        }

                        if (chapter.isIndicatorArea) {
                            chapter.indicatorPackages.forEach((indicatorPackage) => {
                                const pkg = c.indicatorPackage.find((pkg: any) => pkg.id === indicatorPackage.id);
                                if (pkg) {
                                    if (pkg.version !== indicatorPackage.version) {
                                        this.getIndicatorPackage(indicatorPackage.id);
                                    }
                                } else {
                                    this.getIndicatorPackage(indicatorPackage.id);
                                }
                            });
                        }

                        if (chapter.isEvaDecisionOptionArea) {
                            chapter.evaDecisionOptions.forEach((evaDecisionOption) => {
                                const option = c.evaDecisionOptions.find(
                                    (option: any) => option.id === evaDecisionOption.id,
                                );
                                if (option) {
                                    if (option.version !== evaDecisionOption.version) {
                                        this.getChapter(c.id);
                                    }
                                } else {
                                    this.getChapter(c.id);
                                }
                            });
                        }
                    } else {
                        this.reloadCurrentDocument();
                    }
                });

                runInAction(() => {
                    this.activeDocument.chapters = this.activeDocument.chapters.filter((chapter) =>
                        response.data.some((c: any) => c.id === chapter.id),
                    );
                });
            }
        }
    }

    public async getChapter(chapterId: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/getChapter',
            data: { chapterId },
        });
        if (this.activeDocument.chapters.some((chapter) => chapter.id === response.data.id)) {
            this.updateChapterData(response.data as Chapter);
        } else {
            runInAction(() => {
                this.activeDocument.chapters.push(response.data as Chapter);
            });
        }
    }

    public async getIndicatorPackage(id: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/getIndicatorPackage',
            data: { id },
        });
        if (response.status === 200) {
            runInAction(() => {
                const chapter = this.activeDocument.chapters.find((c: Chapter) => c.id === response.data.chapterId);
                if (chapter) {
                    const indicatorPackage = chapter.indicatorPackages.find(
                        (ind: IndicatorPackage) => ind.id === response.data.id,
                    );
                    if (indicatorPackage) {
                        indicatorPackage.notes = response.data.notes;
                        indicatorPackage.name = response.data.name;
                        indicatorPackage.editor = response.data.editor;
                        indicatorPackage.lastUpdated = response.data.lastUpdated;
                        indicatorPackage.version = response.data.version;
                    }
                }
            });
        }
    }

    public async removeCurrentDocument(): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/remove',
            data: { documentId: this.activeDocument.id },
        });
        runInAction(() => {
            this.organizationDocs = this.organizationDocs.filter((doc) => doc.id !== this.activeDocument.id);
        });
    }

    public async saveCurrentDocument(): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/saveDocument',
            data: this.currentDocument,
        });
        runInAction(() => {
            if (this.organizationDocs.find((d) => d.id === this.currentDocument.id)) {
                this.organizationDocs.find((d) => d.id === this.currentDocument.id)!.name = this.currentDocument.name;
            }
        });
    }

    public async fetchPublishedDocuments(): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'document/listPublishedDocuments',
        });
        if (response?.status === 200) {
            runInAction(() => {
                this.allPublishedDocs = response.data;
                // Filter documents that have acceptedDate defined
                const filtered = this.allPublishedDocs.filter((doc: PublishedDocument) => doc.acceptedDate);
                // Latest first
                this.latestPublishedDocs = filtered.sort((a: PublishedDocument, b: PublishedDocument) => {
                    return new Date(b.acceptedDate!).getTime() - new Date(a.acceptedDate!).getTime();
                });
            });
        }
    }

    public async fetchDocuments(params: DocumentSearchParameters): Promise<DocumentSearchDocument[]> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/findDocuments',
            data: params,
        });
        return response.data;
    }

    public async fetchDocumentsForOrganizations(
        organizationIds: number[],
        userId?: number,
    ): Promise<OrganizationDocument[]> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/listDocumentsForOrganizations',
            data: {
                organizations: organizationIds,
                userId,
            },
        });
        return response.data;
    }

    public async fetchOrganizationDocuments(organizationId: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/listOrganizationDocuments',
            data: {
                organizationId,
            },
        });
        runInAction(() => (this.organizationDocs = response.data));
    }

    public async fetchHistoryTitles(date: string): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/getHistoryTitles',
            data: {
                id: this.currentDocument.id,
                date: date,
            },
        });
        runInAction(() => (this.currentDocumentHistoryTitles = response.data));
    }

    public async fetchHistoryDates(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/getHistoryDates',
            data: {
                id: this.currentDocument.id,
            },
        });
        runInAction(() => {
            if (response.data.length > 0) {
                this.currentDocumentHistoryDates = response.data;
            } else {
                this.currentDocumentHistoryDates = [];
                //if there is no history dates then there are no history titles.
                this.currentDocumentHistoryTitles = [];
            }
        });
    }

    public async downloadWordOrPdf(
        type: 'word' | 'pdf',
        colorSchema: string,
        indicatorGraphMode: string,
        printChapterList: boolean,
        chaptersToPrint: number[],
        indicatorDescription: boolean,
        indicatorsToPrint: IndicatorPrintOption[],
    ): Promise<void> {
        const evaDecisionComparison =
            this.isEvaDocument(this.currentDocument) &&
            this.evaDecisionOptionChapter &&
            this.evaDecisionOptionChapter.evaDecisionOptions.length
                ? this.createEvaDecisionOptionCompareTable
                : [];

        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + `document/${type === 'pdf' ? 'download' : 'convertWordDocument'}`,
            data: {
                id: this.currentDocument.id,
                TOC: [],
                chaptersToPrint,
                colorSchema,
                evaDecisionComparison: evaDecisionComparison,
                evaDecisionOptions: this.evaDecisionOptionChapter?.evaDecisionOptions || [],
                hideFocusAreaTable: this.focusAreaTable,
                images: [],
                indicatorDescription,
                indicatorGraphMode,
                indicatorsToPrint,
                printChapterList,
            },
        });
        const mimeType =
            type === 'pdf'
                ? 'application/pdf'
                : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        const fileType = type === 'pdf' ? '.pdf' : '.docx';
        downloadjs(`data:${mimeType};base64,${response.data}`, `${this.currentDocument.name}${fileType}`, fileType);
    }

    public async downloadExcelTemplate(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/downloadExcelTemplate',
        });
        downloadjs(
            `data:application/vnd.ms-excel;base64,${response.data}`,
            'Sähköinen hyvinvointikertomus_useamman indikaattorin lisääminen esimerkki.xlsx',
            '.xlsx',
        );
    }

    public async fetchHistoryById(id: number): Promise<DocumentHistoryItem> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/getHistoryByHistoryId',
            data: {
                id,
            },
        });
        return response.data;
    }

    public async restoreDeletedChapter(chapterId: number, historyId: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/restoreDeletedChapter',
            data: {
                chapterId,
                historyId,
            },
        });
        await this.chaptersStatus();
        runInAction(() => this.currentDocumentHistoryTitles.unshift(response.data as DocumentHistoryTitle));
    }

    public async loadDocumentByShareId(shareToken: string): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/loadSharedDocumentById',
            data: { id: shareToken },
        });
        if (response.status === 200) this.setCurrentDocument(response.data);
    }

    public async shareDocument(params: ShareDocumentParams): Promise<{ code: string }> {
        const evaDecisionComparison = this.isEvaDocument(this.currentDocument)
            ? this.createEvaDecisionOptionCompareTable
            : [];
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/share',
            data: { ...params, evaDecisionComparisonTable: evaDecisionComparison },
        });
        return response.data;
    }

    public async removeWorkgroupUser(userId: number): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/removeWorkgroupUser',
            data: {
                documentId: this.currentDocument.id,
                userId,
            },
        });
        runInAction(() => {
            this.activeDocument.users = this.activeDocument.users.filter((user) => user.id !== userId);
        });
    }

    public async addWorkgroupUser(userName: string): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/addWorkgroupUser',
            data: {
                documentId: this.currentDocument.id,
                userName,
            },
        });
        runInAction(() => {
            this.activeDocument.users.push(response.data);
        });
    }

    public async changeDocumentOwner(userId: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/changeDocumentOwner',
            data: {
                documentId: this.currentDocument.id,
                newOwner: userId,
            },
        });
        runInAction(() => {
            this.activeDocument.owner = response.data;
        });
    }

    public async fetchPreviousDocumentShares(): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/listDocumentShares',
            data: {
                id: this.currentDocument.id,
            },
        });
        runInAction(() => (this.documentShares = response.data));
    }

    public async removeDocumentShare(id: string): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/removeShare',
            data: {
                id,
            },
        });
        const filtered = this.documentShares.filter((share) => share.id !== id);
        runInAction(() => (this.documentShares = filtered));
    }

    public async removeAllDocumentShares(): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/removeAllShares',
            data: {
                id: this.currentDocument.id,
            },
        });
        runInAction(() => (this.documentShares = []));
    }

    public async approveDocument(params: DocumentApprovalParams): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/approve',
            data: params,
        });
        if (!this.isCurrentDocumentEva) {
            await this.changeDocumentIsOfficial(true);
        }
        await this.fetchOrganizationDocuments(this.currentDocument.organization.id);
    }

    public async convertEvaMiniToLarge(documentId: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/convertToLarge',
            data: { id: documentId },
        });
        await this.closeCurrentDocument();
        await this.loadDocumentById(response.data.id);
    }

    public async disapproveDocument(): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/disapprove',
            data: { id: this.currentDocument.id },
        });
        await this.fetchOrganizationDocuments(this.currentDocument.organization.id);
    }

    public async uploadCoverPageImage(file: File): Promise<void> {
        const form = new FormData();
        form.append('file', file);
        form.append('id', this.currentDocument.id.toString());

        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/upload',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: form,
        });
    }

    public async reloadCurrentDocument(): Promise<void> {
        if (this.isPreview) {
            await this.loadPublishedDocumentById(this.currentDocument.id);
        } else {
            await this.loadDocumentById(this.currentDocument.id);
        }
    }

    public async refreshCurrentDocument(): Promise<void> {
        const documentId = this.currentDocument.id;
        await this.closeCurrentDocument();
        await this.loadDocumentById(documentId);
    }

    public addCurrentDocumentIndicators(theme: IndicatorPackageTheme, newIndicators: Indicator[]): void {
        newIndicators.forEach((indicator) => {
            const code = indicator.source.code;

            if (code === 'SOTKANET' || code === 'CHP_SOTKANET') {
                theme?.sotkanetIndicators.push(indicator);
            } else if (code === 'CUSTOM' || code === 'CHP_CUSTOM') {
                theme?.organizationIndicators.push(indicator);
            } else if (code === 'OTHER' || code === 'CHP_OTHER') {
                theme?.otherIndicators.push(indicator);
            }
        });
    }

    public async updateEvaImpactOnTargetTitle(
        impactOnTarget: EditorImpactOnTarget | null | undefined,
        newTitle: string,
    ): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/updateEvaImpactOnTargetTitle',
            data: {
                documentId: this.currentDocument.id,
                impactOnTargetId: impactOnTarget?.id,
                newTitle,
            },
        });
        impactOnTarget && runInAction(() => (impactOnTarget.target = newTitle));
    }

    public removeCurrentDocumentImpactOnTarget(params: EditorImpactOnTarget): void {
        const decisionOptions = this.evaDecisionOptionChapter?.evaDecisionOptions;
        decisionOptions?.forEach((decisionOption) => {
            decisionOption.evaPerspectives.forEach((perspective) => {
                perspective.impactTargets = perspective.impactTargets.filter(
                    (impactOnTarget) => impactOnTarget.id !== params.id,
                );
            });
        });
    }

    public async addEvaImpactOnTarget(params: AddEvaImpactOnTargetParams): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/addEvaImpactOnTarget',
            data: params,
        });
        runInAction(() => {
            if (response.status === 200) {
                response.data.forEach((impact: EditorImpactOnTarget) =>
                    this.currentDocument.chapters
                        .find((chapter) => chapter.isEvaDecisionOptionArea)!
                        .evaDecisionOptions.find((option: EditorDecisionOption) =>
                            option.evaPerspectives.find(
                                (perspective: EditorPerspective) => perspective.id === impact.evaPerspectiveId,
                            ),
                        )!
                        .evaPerspectives.find(
                            (perspective: EditorPerspective) => perspective.id === impact.evaPerspectiveId,
                        )!
                        .impactTargets.push(impact),
                );
            }
        });
    }

    public async saveEvaImpactOnTarget(impact: EditorImpactOnTarget): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/saveEvaImpactOnTarget',
            data: impact,
        });
    }

    public async removeEvaImpactOnTarget(impact: EditorImpactOnTarget, chapter?: Chapter | null): Promise<void> {
        const chapterId = chapter?.id;
        const { target } = impact;
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'eva/removeEvaImpactOnTarget',
            data: {
                chapterId: chapterId,
                target: target,
            },
        });
        this.removeCurrentDocumentImpactOnTarget(impact);
    }

    public removeCurrentDocumentImpactOnTargetIndicator(params: RemoveIndicatorFromImpactOnTargetParams): void {
        const decisionOptions = this.evaDecisionOptionChapter?.evaDecisionOptions;
        decisionOptions?.forEach((decisionOption) => {
            decisionOption.evaPerspectives.forEach((perspective) => {
                perspective.impactTargets.forEach((impactOnTarget) => {
                    if (impactOnTarget.id === params.impactId) {
                        if (params.source.code === 'SOTKANET') {
                            impactOnTarget.sotkanetIndicators = impactOnTarget.sotkanetIndicators.filter(
                                (indicator) => indicator.id !== params.indicatorId,
                            );
                        } else if (params.source.code === 'CUSTOM') {
                            impactOnTarget.organizationIndicators = impactOnTarget.organizationIndicators.filter(
                                (indicator) => indicator.id !== params.indicatorId,
                            );
                        }
                    }
                });
            });
        });
    }

    public addCurrentDocumentImpactOnTargetIndicators(impactOnTargetId: number, newIndicators: Indicator[]): void {
        const decisionOptions = this.evaDecisionOptionChapter?.evaDecisionOptions;
        decisionOptions?.forEach((decisionOption) => {
            decisionOption.evaPerspectives.forEach((perspective) => {
                perspective.impactTargets.forEach((impactOnTarget) => {
                    if (impactOnTarget.id === impactOnTargetId) {
                        newIndicators.forEach((indicator) => {
                            if (indicator.source.code === 'SOTKANET') {
                                impactOnTarget.sotkanetIndicators.push(indicator);
                            } else if (indicator.source.code === 'CUSTOM') {
                                impactOnTarget.organizationIndicators.push(indicator);
                            }
                        });
                    }
                });
            });
        });
    }

    public removeCurrentDocumentIndicator(params: RemoveIndicatorParams): void {
        this.indicatorPackageChapter?.indicatorPackages.forEach((indicatorPackage) => {
            indicatorPackage.themes.forEach((theme) => {
                const code = params.source.code;

                if (code === 'SOTKANET' || code === 'CHP_SOTKANET') {
                    theme.sotkanetIndicators = theme.sotkanetIndicators.filter(
                        (indicator) => indicator.id !== params.id,
                    );
                } else if (code === 'CUSTOM' || code === 'CHP_CUSTOM') {
                    theme.organizationIndicators = theme.organizationIndicators.filter(
                        (indicator) => indicator.id !== params.id,
                    );
                } else if (code === 'OTHER' || code === 'CHP_OTHER') {
                    theme.otherIndicators = theme.otherIndicators.filter((indicator) => indicator.id !== params.id);
                }
            });
        });
    }

    public async lockChapter(chapterId: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/lockChapter',
            data: { chapterId },
        });
        this.updateChapterLocked(response.data.id);
        this.updateChapterData(response.data as Chapter);
    }

    public async lockEveryChapter(allChapters: number[]): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/lockAllChapters',
            data: { allChapters },
        });
        if (response.status === 200) {
            response.data.forEach((chapter: Chapter) => {
                this.updateChapterLocked(chapter.id);
                this.updateChapterData(chapter);
            });
        }
    }

    public async unlockChapter(chapterId: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/unlockChapter',
            data: { chapterId },
        });
        this.updateChapterLocked(response.data.id);
    }

    public async lockFocusAreaChapter(): Promise<void> {
        const focusAreaChapter = this.currentDocument.chapters.find((chapter) => chapter.isFocusArea);
        if (focusAreaChapter && !focusAreaChapter.locked) await this.lockChapter(focusAreaChapter.id);
    }

    public async unlockFocusAreaChapter(): Promise<void> {
        const focusAreaChapter = this.currentDocument.chapters.find((chapter) => chapter.isFocusArea);
        if (focusAreaChapter) await this.unlockChapter(focusAreaChapter.id);
    }

    public async lockDecisionOptionChapter(): Promise<void> {
        if (this.evaDecisionOptionChapter) await this.lockChapter(this.evaDecisionOptionChapter.id);
    }

    public async unlockDecisionOptionChapter(): Promise<void> {
        if (this.evaDecisionOptionChapter) await this.unlockChapter(this.evaDecisionOptionChapter.id);
    }

    public async lockAllChapters(): Promise<void> {
        try {
            const lockedChapterIds = this.currentDocument.chapters
                .filter((chapter) => !chapter.locked)
                .map((chapter) => chapter.id);
            await this.lockEveryChapter(lockedChapterIds);
        } catch (error) {
            await this.releaseChapterLocks();
            throw error;
        }
    }

    public async changeDocumentIsOfficial(isOfficial?: boolean): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/changeDocumentIsOfficial',
            data: { documentId: this.currentDocument.id, isOfficial },
        });
        runInAction(() => {
            if (response.status === 200) {
                this.currentDocument.isOfficial = response.data;
            }
        });
    }

    public async fetchOfficialDocuments(
        type?: 'LARGE_REPORT' | 'YEARLY_REPORT',
    ): Promise<{ documentId: number; documentName: string; organizationName: string }[]> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/getOfficialDocuments',
            data: { documentId: this.currentDocument.id, type },
        });
        if (response.status === 200) return response.data;
        else return [];
    }

    public async lockPackage(chapterId: number): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/lockPackage',
            data: { chapterId },
        });
        runInAction(() => {
            if (response.status === 200) {
                const indicatorPackage = this.currentDocument.chapters
                    .find((chapter) => chapter.isIndicatorArea)!
                    .indicatorPackages.find((pack) => pack.id === response.data.id)!;
                indicatorPackage.notes = response.data.notes;
                indicatorPackage.lastUpdated = response.data.lastUpdated;
            }
        });
    }

    public async unlockPackage(chapterId: number): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/unlockPackage',
            data: { chapterId },
        });
    }

    public async releaseChapterLocks(): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/releaseChapterLocks',
            data: { documentId: this.currentDocument.id },
        });
        runInAction(() =>
            this.currentDocument.chapters.map((chp) => {
                if (chp.locked) {
                    chp.locked = false;
                    return chp;
                }
                return chp;
            }),
        );
    }

    public updateChapterLocked(id: number) {
        const chapter = this.currentDocument.chapters.find((chp) => chp.id === id);
        if (chapter) {
            chapter.locked = !chapter.locked;
        }
    }

    public updateChapterText(text: string, id: number): void {
        const chapter = this.activeDocument.chapters.find((chapter) => chapter.id === id);
        if (chapter) chapter.text = text;
    }

    public updateChapterTitle(title: string, id: number): void {
        const chapter = this.activeDocument.chapters.find((chapter) => chapter.id === id);
        if (chapter) chapter.title = title;
    }

    public updateChapterEditor(editor: string, id: number): void {
        const chapter = this.activeDocument.chapters.find((chapter) => chapter.id === id);
        if (chapter) chapter.editor = editor;
    }

    public updateChapterLastUpdated(updated: string, id: number): void {
        const chapter = this.activeDocument.chapters.find((chapter) => chapter.id === id);
        if (chapter) chapter.lastUpdated = updated;
    }

    public updateChapterVersion(version: number, id: number): void {
        const chapter = this.activeDocument.chapters.find((chapter) => chapter.id === id);
        if (chapter) chapter.version = version;
    }

    public updateChapterFocusAreas(focusAreas: FocusArea[], id: number): void {
        const chapter = this.activeDocument.chapters.find((chapter) => chapter.id === id);
        if (chapter) chapter.focusAreas = focusAreas;
    }

    public updateChapterEvaDecisionOptions(evaDecisionOptions: EditorDecisionOption[], id: number): void {
        const chapter = this.activeDocument.chapters.find((chapter) => chapter.id === id);
        if (chapter) chapter.evaDecisionOptions = evaDecisionOptions;
    }

    public updateChapterData(data: Chapter): void {
        this.updateChapterText(data.text, data.id);
        this.updateChapterTitle(data.title, data.id);
        this.updateChapterEditor(data.editor, data.id);
        this.updateChapterLastUpdated(data.lastUpdated as string, data.id);
        this.updateChapterVersion(data.version, data.id);
        this.updateChapterFocusAreas(data.focusAreas, data.id);
        this.updateChapterEvaDecisionOptions(data.evaDecisionOptions, data.id);
    }

    public async saveChapter(chapter: Chapter) {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/saveChapter',
            data: {
                id: chapter.id,
                locked: true,
                text: chapter.text,
                title: chapter.title,
            },
        });
        this.updateChapterText(response.data.text, response.data.id);
        this.updateChapterTitle(response.data.title, response.data.id);
        this.updateChapterEditor(response.data.editor, response.data.id);
        this.updateChapterLastUpdated(response.data.lastUpdated, response.data.id);
        this.updateChapterVersion(response.data.version, response.data.id);
    }

    public async removeChapter(chapterId: number) {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/removeChapter',
            data: {
                chapterId: chapterId,
            },
        });
    }

    public async createChapter(
        documentId: number,
        orderNumber: number,
        chapterLevel: number,
        title?: string,
        text?: string,
    ): Promise<void> {
        await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/createChapter',
            data: {
                orderNumber: orderNumber,
                chapterLevel: chapterLevel,
                documentId: documentId,
                title: title,
                text: text,
            },
        });
    }

    public async downloadPowerPoint(params: DownloadPowerPointParams): Promise<void> {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'document/downloadPowerPoint',
            data: { ...params },
        });
        return response.data;
    }

    public async savePackage(indicatorPackage: IndicatorPackage) {
        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/savePackage',
            data: {
                id: indicatorPackage.id,
                locked: true,
                description: '',
                notes: indicatorPackage.notes,
                name: '',
            },
        });
        runInAction(() => {
            if (response.status === 200) {
                const indicatorPackage = this.currentDocument.chapters
                    .find((chapter) => chapter.isIndicatorArea)!
                    .indicatorPackages.find((pack) => pack.id === response.data.id)!;
                indicatorPackage.notes = response.data.notes;
                indicatorPackage.lastUpdated = response.data.lastUpdated;
                indicatorPackage.editor = response.data.editor;
                indicatorPackage.version = response.data.version;
            }
        });
    }

    public async uploadIndicator(file: File): Promise<AddCustomIndicator[]> {
        const form = new FormData();
        form.append('file', file);
        form.append('id', this.currentDocument.organization.id.toString());

        const response = await Axios({
            method: 'POST',
            url: BACKEND_URL + 'indicator/upload',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: form,
        });
        if (response.status === 200) {
            return response.data;
        } else return [];
    }

    public get previousDocumentShares(): DocumentShare[] {
        return this.documentShares.slice().sort((a, b) => {
            return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
        });
    }

    public get documentHistoryTitles(): DocumentHistoryTitle[] {
        return this.currentDocumentHistoryTitles.slice().sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
    }

    public get documentHistoryDates(): string[] {
        return this.currentDocumentHistoryDates.slice().sort((a, b) => {
            return new Date(b).getTime() - new Date(a).getTime();
        });
    }

    public get documentTypes(): ShvkDocumentType[] {
        return this.docTypes;
    }

    public get latestPublishedDocuments(): PublishedDocument[] {
        return this.latestPublishedDocs;
    }

    public get organizationShvkDocuments(): OrganizationDocument[] {
        return this.organizationDocs.filter(
            (d) =>
                d.type.code === 'YEARLY_REPORT' ||
                d.type.code === 'LARGE_REPORT' ||
                d.type.code === 'REGIONAL_YEARLY_REPORT' ||
                d.type.code === 'REGIONAL_LARGE_REPORT',
        );
    }

    public get organizationEvaDocuments(): OrganizationDocument[] {
        return this.organizationDocs.filter((d) => d.type.code === 'EVA' || d.type.code === 'EVA_MINI');
    }

    public get organizationAnotherDocuments(): OrganizationDocument[] {
        return this.organizationDocs.filter((d) => d.type.code === 'ANOTHER_REPORT');
    }

    public get isCurrentDocumentEva(): boolean {
        return this.isEvaDocument(this.currentDocument);
    }

    public isEvaDocument(doc: string): boolean;
    public isEvaDocument(doc: PublishedDocument): boolean;
    public isEvaDocument(doc: CurrentDocument): boolean;
    public isEvaDocument(doc: any): boolean {
        if ((doc as CurrentDocument).type?.code !== undefined) {
            return doc.type.code === 'EVA' || doc.type.code === 'EVA_MINI';
        } else if ((doc as PublishedDocument).type) {
            return doc.type === 'EVA' || doc.type === 'EVA_MINI';
        } else if (typeof doc === 'string') {
            return doc === 'EVA' || doc === 'EVA_MINI';
        } else return false;
    }

    public getDocumentTypeByCode(code: string): ShvkDocumentType | null {
        const type = this.documentTypes.find((type) => type.code === code);
        return type || null;
    }

    public getOrganizationDocumentById(id: number): OrganizationDocument | null {
        const document = this.organizationDocs.find((doc) => doc.id === id);
        return document || null;
    }

    public get evaDecisionOptionChapter(): Chapter | null {
        const chapter = this.activeDocument.chapters.find((c) => c.isEvaDecisionOptionArea);
        return chapter || null;
    }

    public get indicatorPackageChapter(): Chapter | null {
        const chapter = this.activeDocument.chapters.find((c) => c.indicatorPackages.length > 0);
        return chapter || null;
    }

    private get nullDocument(): CurrentDocument {
        return {
            acceptanceClause: null,
            acceptedBy: null,
            acceptedDate: null,
            chapters: [],
            coverPageImage: null,
            coverPageText: null,
            creator: { id: 0, userName: '' },
            dateCreated: '',
            id: 0,
            journalNumber: null,
            language: 'fi_FI',
            name: '',
            organization: {
                active: false,
                category: '',
                code: '',
                id: 0,
                latitude: 0,
                longitude: 0,
                uri: '',
                name: '',
                shortName: '',
                isCollectionCategory: false,
                isRegional: false,
                isPartOfRegional: false,
            },
            owner: { id: 0, firstName: '', lastName: '' },
            sharedData: '',
            status: {
                id: 0,
                code: 'STATUS_COMPLETE',
            },
            type: {
                id: 0,
                code: 'LARGE_REPORT',
                description: {
                    id: 0,
                    en_GB: '',
                    fi_FI: '',
                    key: '',
                    sv_SE: '',
                },
                name: '',
            },
            users: [],
            yearBegin: null,
            yearEnd: null,
            documentIndicatorSetting: {
                changeYearsVisibility: 0,
                compareDirection: 0,
                documentId: 0,
                graphScale: 0,
                id: 0,
                organizationName: '',
                organizations: [],
            },
            focusAreaTableTitles: {
                actionTitle: '',
                additionalColumnByActionTitle: '',
                additionalColumnByGoalTitle: '',
                evaluationGaugeTitle: '',
                resourceTitle: '',
            },
        };
    }

    public async closeCurrentDocument(): Promise<void> {
        this.activeDocument.id && !this.isPreview && (await this.releaseChapterLocks());
        runInAction(() => (this.rootModule.indicator.summaryData = null));
        runInAction(() => (this.activeDocument = this.nullDocument));
    }

    public get isCurrentDocumentApproved(): boolean {
        return this.activeDocument.status.code === 'STATUS_COMPLETE';
    }

    public get isCurrentDocumentEvaMini(): boolean {
        return this.activeDocument.type.code === 'EVA_MINI';
    }

    public get isAnotherReport(): boolean {
        return this.activeDocument.type.code === 'ANOTHER_REPORT';
    }

    public get hasSummary(): boolean {
        return this.activeDocument.chapters.some((chapter) => chapter.isSummary);
    }

    public get isLargeReport(): boolean {
        return (
            this.activeDocument.type.code === 'LARGE_REPORT' ||
            this.activeDocument.type.code === 'REGIONAL_LARGE_REPORT'
        );
    }

    public get hasUserRightsToCurrentDocument(): boolean {
        return this.isUserCurrentDocumentOrganizationAdmin || this.isUserCurrentDocumentCreatorOrOwner;
    }

    public get isUserCurrentDocumentOrganizationAdmin(): boolean {
        const organizationUser = this.rootModule.user.userDetails?.organizations.find(
            (org) => org.id === this.currentDocument.organization.id,
        );
        return organizationUser?.isAdmin || false;
    }

    public get isUserCurrentDocumentCreatorOrOwner(): boolean {
        const userId = this.rootModule.user.userDetails?.id;
        return this.currentDocument.owner.id === userId || this.currentDocument.creator.id === userId;
    }

    public async loadDocumentById(id: number): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'document/loadById/' + id,
        });
        this.setCurrentDocument(response.data);
    }

    public async loadForPreviewById(id: number): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'document/loadForPreviewById/' + id,
        });
        await this.setCurrentDocument(response.data);
    }

    public async loadPublishedDocumentById(id: number): Promise<void> {
        const response = await Axios({
            method: 'GET',
            url: BACKEND_URL + 'document/loadPublishedDocumentById/' + id,
        });
        this.setCurrentDocument(response.data);
    }

    private get createEvaDecisionOptionCompareTable(): any[] {
        const { translate } = this.rootModule.localization;
        const shortTerm = translate('EVA_TERM_EFFECT_PART_1_SHORT') + ' ' + translate('EVA_TERM_EFFECT_PART_3');
        const longTerm = translate('EVA_TERM_EFFECT_PART_2_LONG') + ' ' + translate('EVA_TERM_EFFECT_PART_3');
        const description = translate('EVA_DECISION_OPTION_DESCRIPTION');
        const evaDecisionOptions = this.currentDocument.chapters.find(
            (chapter) => chapter.isEvaDecisionOptionArea,
        )!.evaDecisionOptions;
        const perspectives = evaDecisionOptions[0].evaPerspectives;

        //First create empty table
        const evaDecisionOptionCompareTable: any[] = [];

        perspectives.forEach((perspective, perspectiveIndex) => {
            // Add perspective title
            evaDecisionOptionCompareTable.push([
                {
                    clickable: false,
                    title: perspective.title,
                    type: 'perspectiveTitle',
                },
                ...evaDecisionOptions.map(() => {
                    return {
                        clickable: false,
                        field: 'blank',
                    };
                }),
            ]);
            perspective.impactTargets.forEach((impactTarget, impactIndex) => {
                // Add impact of target title row
                evaDecisionOptionCompareTable.push([
                    {
                        clickable: false,
                        field: 'impact',
                        impactOnTarget: impactTarget,
                        text: impactTarget.target,
                    },
                    ...evaDecisionOptions.map((decisionOption) => {
                        return {
                            clickable: true,
                            field: 'blank',
                            impactOnTarget: decisionOption.evaPerspectives[perspectiveIndex].impactTargets[impactIndex],
                            option: decisionOption,
                            perspectiveId: decisionOption.evaPerspectives[perspectiveIndex].id,
                            text: decisionOption.evaPerspectives[perspectiveIndex].impactTargets[impactIndex]
                                .description,
                        };
                    }),
                ]);
                // Add short term value row
                evaDecisionOptionCompareTable.push([
                    {
                        text: shortTerm,
                        type: 'tableHeading',
                        clickable: false,
                        impactOnTarget: impactTarget,
                    },
                    ...evaDecisionOptions.map((decisionOption) => {
                        return {
                            clickable: true,
                            impactOnTarget: decisionOption.evaPerspectives[perspectiveIndex].impactTargets[impactIndex],
                            option: decisionOption,
                            perspectiveId: decisionOption.evaPerspectives[perspectiveIndex].id,
                            shortTermEffect:
                                decisionOption.evaPerspectives[perspectiveIndex].impactTargets[impactIndex]
                                    .shortTermEffect,
                            type: 'shortTermValue',
                        };
                    }),
                ]);
                // Add long term value row
                evaDecisionOptionCompareTable.push([
                    {
                        clickable: false,
                        impactOnTarget: impactTarget,
                        text: longTerm,
                        type: 'tableHeading',
                    },
                    ...evaDecisionOptions.map((decisionOption) => {
                        return {
                            clickable: true,
                            impactOnTarget: decisionOption.evaPerspectives[perspectiveIndex].impactTargets[impactIndex],
                            longTermEffect:
                                decisionOption.evaPerspectives[perspectiveIndex].impactTargets[impactIndex]
                                    .longTermEffect,
                            option: decisionOption,
                            perspectiveId: decisionOption.evaPerspectives[perspectiveIndex].id,
                            type: 'longTermValue',
                        };
                    }),
                ]);
                // Add comments row
                evaDecisionOptionCompareTable.push([
                    {
                        clickable: false,
                        impactOnTarget: impactTarget,
                        text: description,
                        type: 'tableHeading',
                    },
                    ...evaDecisionOptions.map((decisionOption) => {
                        return {
                            clickable: true,
                            field: 'dop',
                            impactOnTarget: decisionOption.evaPerspectives[perspectiveIndex].impactTargets[impactIndex],
                            option: decisionOption,
                            perspectiveId: decisionOption.evaPerspectives[perspectiveIndex].id,
                            text: decisionOption.evaPerspectives[perspectiveIndex].impactTargets[impactIndex]
                                .description,
                        };
                    }),
                ]);
            });
        });

        return [evaDecisionOptionCompareTable];
    }

    public get currentDocumentIndicators(): Indicator[] {
        const indicators: Indicator[] = [];
        this.indicatorPackageChapter?.indicatorPackages.forEach((indicatorPackage: IndicatorPackage) =>
            indicatorPackage.themes.forEach((indicatorPackageTheme: IndicatorPackageTheme) => {
                indicatorPackageTheme.sotkanetIndicators.forEach((indicator: Indicator) => {
                    indicators.push(indicator);
                });
                indicatorPackageTheme.organizationIndicators.forEach((indicator: Indicator) => {
                    indicators.push(indicator);
                });
                indicatorPackageTheme.otherIndicators.forEach((indicator: Indicator) => {
                    indicators.push(indicator);
                });
            }),
        );
        return indicators;
    }

    public findIndicatorById(id: number): Indicator | undefined {
        let foundIndicator: Indicator | undefined = undefined;
        this.indicatorPackageChapter?.indicatorPackages.forEach((indicatorPackage: IndicatorPackage) =>
            indicatorPackage.themes.forEach((indicatorPackageTheme: IndicatorPackageTheme) => {
                indicatorPackageTheme.sotkanetIndicators.forEach((indicator: Indicator) => {
                    if (indicator.id === id) foundIndicator = indicator;
                });
                indicatorPackageTheme.organizationIndicators.forEach((indicator: Indicator) => {
                    if (indicator.id === id) foundIndicator = indicator;
                });
                indicatorPackageTheme.otherIndicators.forEach((indicator: Indicator) => {
                    if (indicator.id === id) foundIndicator = indicator;
                });
            }),
        );
        return foundIndicator;
    }

    public setIndicatorPackageThemes(themes: IndicatorPackageTheme[], indicatorPackageId: number): void {
        this.activeDocument.chapters = this.activeDocument.chapters.map((chapter) => {
            if (chapter.indicatorPackages.length === 0) return chapter;

            chapter.indicatorPackages = chapter.indicatorPackages.map((indicatorPackage) => {
                if (indicatorPackage.id !== indicatorPackageId) return indicatorPackage;

                indicatorPackage.themes = themes;
                return indicatorPackage;
            });
            return chapter;
        });
    }

    private setCurrentDocument(document: CurrentDocument): void {
        if (document.language !== this.rootModule.localization.locale) {
            this.rootModule.dialog.openLanguageChangedDialog();

            const newLanguage = this.rootModule.localization.languages.find(
                (language) => language.locale === document.language,
            );
            if (newLanguage) this.rootModule.localization.changeLanguage(newLanguage);
        }
        this.activeDocument = document;
        this.createChapterNumbers();
        this.updateChapterVersions();

        // Eva documents doesn't have comparisonSettings
        if (this.isCurrentDocumentEva) {
            this.rootModule.indicator.clearComparisonSettings();
            return;
        }

        if (this.activeDocument.documentIndicatorSetting) {
            this.rootModule.indicator.setComparisonSettings(this.activeDocument.documentIndicatorSetting);
        }
        // todo handleShvkToolLanguageByDocumentLanguage
    }

    private updateChapterVersions(): void {
        const document = this.activeDocument;
        document.chapters = document.chapters.map((chapter) => {
            chapter.chapterCreateOptionItems = this.createChapterVersions(chapter);
            return chapter;
        });
        this.activeDocument = document;
    }

    private createChapterNumbers(): void {
        const doc = this.activeDocument;

        // Make sure that chapters are in order!
        doc.chapters = doc.chapters.slice().sort((a, b) => a.orderNumber - b.orderNumber);
        const chapterNumber = this.createNewChapterNumber();

        doc.chapters = doc.chapters.map((chapter) => {
            if (chapter.chapterLevel === 1) {
                chapterNumber.level1++;
                chapterNumber.level2 = 0;
                chapterNumber.level3 = 0;
            } else if (chapter.chapterLevel === 2) {
                chapterNumber.level2++;
                chapterNumber.level3 = 0;
            } else if (chapter.chapterLevel === 3) {
                chapterNumber.level3++;
            }

            chapter.chapterNumber = chapterNumber;
            chapter.chapterNumberString = this.formatChapterNumber(chapterNumber);

            if (chapter.chapterLevel === 0) {
                chapter.chapterNumberString = '';
            }
            return chapter;
        });
        this.activeDocument = doc;
    }

    private createChapterVersions = (chapter: Chapter): ChapterItem[] => {
        const orderNumber = chapter.orderNumber + 1;
        const chapterLevel = chapter.chapterLevel;
        const chapterNumberString = chapter.chapterNumberString;

        const versions = [];
        if (chapterLevel === 0 && chapterNumberString === null) {
            // find previous chapter which has chapterNumber SET!
            const versionChapter = this.findPreviousChapterWithNumberSet(chapter);

            if (!versionChapter) {
                versions.push(
                    this.createChapterItem(2, 0, this.rootModule.localization.translate('NO_CHAPTER_NUMBER')),
                );
                versions.push(this.createChapterItem(2, 1, '1'));
            }
        } else {
            // add top level chapter "before"
            versions.push(this.createChapterNumberItem(orderNumber - 1, 1, chapterNumberString));
            // add subchapter
            versions.push(this.createChapterNumberItem(orderNumber, 2, chapterNumberString));
            const lastSubChapter = this.findLastSubChapter(chapterNumberString);
            if (lastSubChapter) {
                // add top level chapter after
                versions.push(
                    this.createChapterNumberItem(
                        lastSubChapter.orderNumber + 1,
                        1,
                        (parseInt(chapterNumberString, 10) + 1).toString(),
                    ),
                );
            }
        }

        return versions;
    };

    private findLastSubChapter = (chapterNumberString: string): Chapter | null => {
        const doc = this.activeDocument;

        if (chapterNumberString === null) {
            return null;
        }

        const testNumber = this.chapterNumberFromString(chapterNumberString);
        return (
            doc.chapters.find(
                (chapter) => this.chapterNumberFromString(chapter.chapterNumberString).level1 === testNumber.level1,
            ) || null
        );
    };

    private findPreviousChapterWithNumberSet = (fromChapter: Chapter): Chapter | null => {
        const doc = this.activeDocument;

        const index = doc.chapters.indexOf(fromChapter) || -1;

        // Loop from current pos to beginning...
        for (let i = index; i >= 0; i--) {
            const testChapter = doc.chapters[i];

            if (testChapter?.chapterNumber) {
                return testChapter;
            }
        }
        return null;
    };

    private createChapterItem = (orderNumber: number, chapterLevel: number, title: string): ChapterItem => {
        return {
            orderNumber: orderNumber,
            chapterLevel: chapterLevel,
            optionTitle: title,
        };
    };

    private createChapterNumberItem = (
        orderNumber: number,
        chapterLevel: number,
        chapterNumberString: string,
    ): ChapterItem => {
        const chapterNumber = this.chapterNumberFromString(chapterNumberString);
        chapterNumber.visible = chapterLevel !== 0;

        // Create next "big" chapter
        if (chapterLevel === 1) {
            chapterNumber.level2 = 0;
        } else if (chapterLevel === 2) {
            chapterNumber.level2++;
        } else if (chapterLevel === 3) {
            chapterNumber.level3++;
        }

        let title = this.formatChapterNumber(chapterNumber);
        if (title === '') {
            title = this.rootModule.localization.translate('NO_CHAPTER_NUMBER');
        }

        return this.createChapterItem(orderNumber, chapterLevel, title);
    };

    private chapterNumberFromString = (chapterNumberString: string): ChapterNumber => {
        const chapterNumber = {
            level1: 0,
            level2: 0,
            level3: 0,
        };

        if (!chapterNumberString) {
            return chapterNumber;
        }

        const parts = chapterNumberString.split('.');

        if (parts.length === 0) {
            return chapterNumber;
        }

        switch (parts.length) {
            case 1:
                chapterNumber.level1 = parseInt(parts[0], 10) || 0;
                break;
            case 2:
                chapterNumber.level1 = parseInt(parts[0], 10) || 0;
                chapterNumber.level2 = parseInt(parts[1], 10) || 0;
                break;
            case 3:
                chapterNumber.level1 = parseInt(parts[0], 10) || 0;
                chapterNumber.level2 = parseInt(parts[1], 10) || 0;
                chapterNumber.level3 = parseInt(parts[2], 10) || 0;
                break;
        }

        return chapterNumber;
    };

    private formatChapterNumber = (chapterNumber: ChapterNumber): string => {
        if (chapterNumber.level1 > 0 && chapterNumber.level2 > 0 && chapterNumber.level3 > 0) {
            return '' + chapterNumber.level1 + '.' + chapterNumber.level2 + '.' + chapterNumber.level3;
        } else if (chapterNumber.level1 > 0 && chapterNumber.level2 > 0 && chapterNumber.level3 === 0) {
            return '' + chapterNumber.level1 + '.' + chapterNumber.level2;
        } else if (chapterNumber.level1 > 0 && chapterNumber.level2 === 0 && chapterNumber.level3 === 0) {
            return '' + chapterNumber.level1;
        }
        return '';
    };

    private createNewChapterNumber = (): ChapterNumber => {
        return {
            level1: 0,
            level2: 0,
            level3: 0,
        };
    };
}
